'use client';

import { Link } from '@/i18n/routing';
import { useTranslations } from 'next-intl';
import { ComponentProps } from 'react';
const navigation = {
  social: [
  // {
  //   name: 'Facebook',
  //   href: 'https://www.facebook.com/people/Ragen-AI/61568068525968/',
  //   icon: (props: ComponentProps<'svg'>) => (
  //     <svg fill="currentColor" viewBox="0 0 50 50" {...props}>
  //       <path d="M25,46.433c-5.914,0-11.28-2.378-15.121-6.28c-3.902-3.842-6.28-9.207-6.28-15.122c0-5.914,2.378-11.28,6.28-15.183   c3.841-3.902,9.207-6.28,15.121-6.28c5.915,0,11.28,2.378,15.182,6.28c3.842,3.902,6.221,9.269,6.221,15.183   c0,5.915-2.379,11.28-6.221,15.122C36.279,44.055,30.914,46.433,25,46.433z M22.622,21.799h-3.171v3.964h3.171v9.634h3.964v-9.634   h3.963v-3.964h-3.963v-2.012c0-0.671,0.365-1.22,0.791-1.22h3.172v-3.902h-3.172c-2.622,0-4.755,2.317-4.755,5.183V21.799z" />
  //     </svg>
  //   ),
  // },
  // {
  //   name: 'X',
  //   href: '#',
  //   icon: (props: ComponentProps<'svg'>) => (
  //     <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
  //       <path d="M13.6823 10.6218L20.2391 3H18.6854L12.9921 9.61788L8.44486 3H3.2002L10.0765 13.0074L3.2002 21H4.75404L10.7663 14.0113L15.5685 21H20.8131L13.6819 10.6218H13.6823ZM11.5541 13.0956L10.8574 12.0991L5.31391 4.16971H7.70053L12.1742 10.5689L12.8709 11.5655L18.6861 19.8835H16.2995L11.5541 13.096V13.0956Z" />
  //     </svg>
  //   ),
  // },
  // {
  //   name: 'GitHub',
  //   href: 'https://github.com/ragenai',
  //   icon: (props) => (
  //     <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
  //       <path
  //         fillRule="evenodd"
  //         d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
  //         clipRule="evenodd"
  //       />
  //     </svg>
  //   ),
  // },
  {
    name: 'LinkedIn',
    href: 'https://www.linkedin.com/company/ragenai',
    icon: (props: ComponentProps<'svg'>) => <svg fill="currentColor" viewBox="0 0 50 50" {...props}>
          <path d="M24.969,46.433c-5.914,0-11.28-2.378-15.121-6.28c-3.902-3.902-6.28-9.269-6.28-15.183s2.378-11.28,6.28-15.183   c3.841-3.842,9.207-6.22,15.121-6.22c5.916,0,11.281,2.378,15.183,6.22c3.902,3.902,6.279,9.269,6.279,15.183   s-2.377,11.28-6.279,15.183S30.885,46.433,24.969,46.433z M18.933,12.836c-1.28,0-2.317,1.098-2.317,2.378s1.037,2.317,2.317,2.317   s2.316-1.037,2.316-2.317S20.213,12.836,18.933,12.836z M20.823,18.934h-3.78v13.109h3.78V18.934z M26.434,18.934h-3.782v13.109   h3.782v-8.415c0.549-0.549,1.219-0.854,1.951-0.914c0.365-0.062,0.73,0.061,1.098,0.244c0.305,0.183,0.67,0.67,0.67,1.097v7.988   h3.719v-7.988c0-1.707-1.035-3.475-2.561-4.39c-1.402-0.731-3.414-0.854-4.877-0.122V18.934z" />
        </svg>
  }, {
    name: 'YouTube',
    href: 'https://www.youtube.com/@RagenAI',
    icon: (props: ComponentProps<'svg'>) => <svg fill="currentColor" viewBox="0 0 512 512" {...props}>
          <path d="M501.303,132.765c-5.887,-22.03 -23.235,-39.377 -45.265,-45.265c-39.932,-10.7 -200.038,-10.7 -200.038,-10.7c0,0 -160.107,0 -200.039,10.7c-22.026,5.888 -39.377,23.235 -45.264,45.265c-10.697,39.928 -10.697,123.238 -10.697,123.238c0,0 0,83.308 10.697,123.232c5.887,22.03 23.238,39.382 45.264,45.269c39.932,10.696 200.039,10.696 200.039,10.696c0,0 160.106,0 200.038,-10.696c22.03,-5.887 39.378,-23.239 45.265,-45.269c10.696,-39.924 10.696,-123.232 10.696,-123.232c0,0 0,-83.31 -10.696,-123.238Zm-296.506,200.039l0,-153.603l133.019,76.802l-133.019,76.801Z" />
        </svg>
  }]
};
export function Footer() {
  const t = useTranslations('Footer');
  return <footer className="bg-gray-900 min-w-[404px]" data-sentry-component="Footer" data-sentry-source-file="Footer.tsx">
      <div className="mx-auto max-w-7xl px-6 pb-8 lg:px-8">
        <div className="border-t border-white/10 pt-8 md:flex md:items-center md:justify-between">
          <div className="flex gap-x-6 md:order-2">
            {navigation.social.map(item => <a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-300">
                <span className="sr-only">{item.name}</span>
                <item.icon aria-hidden="true" className="size-6" />
              </a>)}
          </div>
          <p className="mt-8 text-sm/6 text-gray-400 md:order-1 md:mt-0">
            Copyright &copy; {new Date().getFullYear()} Ragen AI Solutions by{' '}
            <a href="https://www.webamigos.pl" target="_blank">
              Web Amigos
            </a>
            . {t('all-rights-reserved')} |{' '}
            <Link href="/terms-of-use" data-sentry-element="Link" data-sentry-source-file="Footer.tsx">{t('terms')}</Link> |{' '}
            <Link href="/privacy-policy" data-sentry-element="Link" data-sentry-source-file="Footer.tsx">{t('privacy-policy')}</Link> |{' '}
            <Link href="/newsletter-policy" data-sentry-element="Link" data-sentry-source-file="Footer.tsx">{t('newsletter-policy')}</Link>
          </p>
        </div>
      </div>
    </footer>;
}