import Link from 'next/link';
import { classMerge } from '@/libs/utils/cn';
const baseStyles = {
  solid: 'group inline-flex items-center justify-center rounded-md py-2 px-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2',
  outline: 'group inline-flex ring-1 items-center justify-center rounded-md py-2 px-4 text-sm focus:outline-none'
};
const variantStyles = {
  solid: {
    slate: 'bg-slate-900 text-white hover:bg-slate-700 hover:text-slate-100 active:bg-slate-800 active:text-slate-300 focus-visible:outline-slate-900',
    blue: 'bg-blue-600 text-white hover:text-slate-100 hover:bg-blue-500 active:bg-blue-800 active:text-blue-100 focus-visible:outline-blue-600',
    white: 'bg-white text-slate-900 hover:bg-blue-50 active:bg-blue-200 active:text-slate-600 focus-visible:outline-white',
    indigo: 'rounded-md bg-indigo-600 px-3.5 py-2.5 text-base font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
  },
  outline: {
    slate: 'ring-slate-200 text-slate-700 hover:text-slate-900 hover:ring-slate-300 active:bg-slate-100 active:text-slate-600 focus-visible:outline-blue-600 focus-visible:ring-slate-300',
    white: 'ring-slate-700 text-white hover:ring-slate-500 active:ring-slate-700 active:text-slate-400 focus-visible:outline-white',
    blue: 'ring-blue-600 text-gray-900 hover:text-blue-900 hover:ring-blue-400 active:bg-blue-100 active:text-slate-600 focus-visible:outline-blue-600 focus-visible:ring-blue-300'
  }
};
type ButtonProps = ({
  variant?: 'solid';
  color?: keyof typeof variantStyles.solid;
} | {
  variant: 'outline';
  color?: keyof typeof variantStyles.outline;
}) & (Omit<React.ComponentPropsWithoutRef<typeof Link>, 'color'> | (Omit<React.ComponentPropsWithoutRef<'button'>, 'color'> & {
  href?: undefined;
}));
export function Button({
  className,
  ...props
}: ButtonProps) {
  props.variant ??= 'solid';
  props.color ??= 'slate';
  className = classMerge(baseStyles[props.variant], props.variant === 'outline' ? variantStyles.outline[props.color] : props.variant === 'solid' ? variantStyles.solid[props.color] : undefined, className);
  return typeof props.href === 'undefined' ? <button className={className} {...props} data-sentry-component="Button" data-sentry-source-file="Button.tsx" /> : <Link className={className} {...props} data-sentry-element="Link" data-sentry-component="Button" data-sentry-source-file="Button.tsx" />;
}